import { FormattedDate, FormattedList } from "react-intl";
import {
  Input,
  Measurement,
  MeasurementsListItem,
  defaultFilters,
  filterEquals,
  useMeasurements,
} from "api/measurements";
import Map, { MapToggle } from "./Map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CalibrationText } from "lib/calibrations";
import { ComprehensivenessTitle } from "./ComprehensivenessIndicator";
import Filter from "./Filter";
import ListActions from "./ListActions";
import Pagination from "./Pagination";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import Table from "./Table";
import { classes } from "lib/helpers";
import errorImage from "images/error.svg";
import styles from "./List.module.scss";
import { useAccount } from "api/account";

function ComprehensivenessExplanation() {
  return (
    <div className={styles.comprehensivenessExplanation}>
      <ComprehensivenessTitle />{" "}
      <span>
        <T id="measurement.comprehensivenessIndicator" />
      </span>
    </div>
  );
}

function List({
  input,
  onInput,
}: {
  input: Input;
  onInput: (input: Input) => void;
}) {
  const [mapOpen, setMapOpen] = useState(false);
  const [resetToBounds, setResetToBounds] = useState(true);
  const [output, loading] = useMeasurements(input);
  const [checked, setChecked] = useState<MeasurementsListItem[]>([]);
  const [hovered, setHovered] = useState<
    MeasurementsListItem | Measurement | null
  >(null);

  const showResetFilters = useMemo(
    () => !filterEquals(input, defaultFilters),
    [input]
  );

  const [account] = useAccount();

  useEffect(() => setChecked([]), [input]);

  const toggleMeasurement = useCallback(
    (m) =>
      setChecked((checked) =>
        checked.includes(m) ? checked.filter((x) => x !== m) : checked.concat(m)
      ),
    []
  );

  useEffect(() => {
    setResetToBounds(true);
  }, [output]);

  if (!output) {
    return <Spinner />;
  }

  return (
    <>
      <h1>
        <T id="measurements" />
      </h1>
      <div className={classes(styles.root, loading && styles.loading)}>
        <div className={classes("dashboardTop", styles.controls)}>
          <div className={styles.right}>
            <div className={styles.actionGroup}>
              <Filter
                filters={input}
                onFilters={(filters) => onInput({ ...input, ...filters })}
              />
              <ListActions
                measurements={checked}
                onDelete={() => onInput({ ...input }) /* Force API call */}
                onGroup={() => onInput({ ...input })}
              />
            </div>
            <div className={styles.filterDescription}>
              <span>
                <T id="measurements.filters.description.showing" />{" "}
                <strong>
                  {input.calibration__uuid__in.length ? (
                    <FormattedList
                      type="conjunction"
                      value={input.calibration__uuid__in.map((c) => (
                        <CalibrationText key={c} calibration={c} />
                      ))}
                    />
                  ) : (
                    <T id="measurements.filters.description.allSpecies" />
                  )}
                </strong>
                {input.measured_at__gte ? (
                  <>
                    {" "}
                    <T id="measurements.filters.description.from" />{" "}
                    <strong>
                      <FormattedDate value={input.measured_at__gte} />
                    </strong>
                  </>
                ) : null}
                {input.measured_at__lte ? (
                  <>
                    {" "}
                    <T id="measurements.filters.description.to" />{" "}
                    <strong>
                      <FormattedDate value={input.measured_at__lte} />
                    </strong>
                  </>
                ) : null}
              </span>
              {showResetFilters ? (
                <button
                  className="cta small"
                  onClick={() => onInput({ ...input, ...defaultFilters })}
                >
                  <T id="measurements.filters.reset" />
                </button>
              ) : null}
            </div>
          </div>
          <div className={styles.left}>
            <MapToggle open={mapOpen} onOpen={setMapOpen} />
            <Pagination
              count={output.count}
              pagination={input}
              onPagination={(pagination) =>
                onInput({ ...input, ...pagination })
              }
            />
          </div>
          {account?.is_vad ? (
            <div className={styles.vadWarning}>
              <img src={errorImage} alt="error" />
              <T id="measurements.vadWarning" />
            </div>
          ) : null}
        </div>
        <div className={styles.main}>
          <div className={styles.tableContainer}>
            <ComprehensivenessExplanation />
            <Table
              measurements={output.results}
              ordering={input.ordering}
              onOrdering={(ordering) => onInput({ ...input, ordering })}
              checked={checked}
              hovered={hovered}
              onChecked={setChecked}
            />
          </div>
          <div
            className={classes("mapBackground", mapOpen && "open")}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setMapOpen(false);
              }
            }}
          >
            <div className="map">
              <Map
                measurements={output.results.filter(function (
                  item: MeasurementsListItem
                ) {
                  return item.isGroup === false;
                })}
                onHovered={setHovered}
                onClick={toggleMeasurement}
                resetToBounds={resetToBounds}
                setResetToBounds={setResetToBounds}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
